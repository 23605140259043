import React, { useState } from 'react'
import useSWR from 'swr'
import styled from 'styled-components'
import { getVideosFromGeneral } from 'services/youtube'

const Select = styled.select`
  border: 1px solid ${({ theme }) => theme.color.face.black};
  max-width: 280px;
`

function VideosByMonth() {
  const { data: videos, error } = useSWR('general', getVideosFromGeneral, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
  })
  const [value, setValue] = useState(undefined)

  if (error)
    return <div>Er is iets fout gegaan met het ophalen van de gegevens.</div>
  if (!videos) return <div>Gegevens ophalen..</div>

  const options = Array.from(new Set(videos.map((video) => video.value)))

  function handleChange(e) {
    setValue(e.target.value)
  }

  const videosByMonth = videos.filter((video) => {
    if (value === undefined) {
      return video.value === videos[0].value
    }

    return video.value === value
  })

  return (
    <div>
      <Select
        className="w-100 py-2 text-capitalize mb-4 col-lg-6 px-0"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, key) => (
          <option value={option} key={key}>
            {option}
          </option>
        ))}
      </Select>

      {videosByMonth.length > 0 ? (
        <ul className="pl-4 list-style-none">
          {videosByMonth.map((video) => (
            <li className="mb-1" key={video.etag}>
              <div>
                <span className="mr-3">{'- '}</span>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`https://youtube.com/watch?v=${video.snippet.resourceId.videoId}`}
                  className="letter-spacing-5"
                >
                  {video.snippet.title}
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div>Geen diensten gevonden in {value}</div>
      )}
    </div>
  )
}

export default VideosByMonth
