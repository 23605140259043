import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'

const Select = styled.select`
  border: 1px solid ${({ theme }) => theme.color.face.black};
  max-width: 280px;
`

const Downloads = styled.div`
  a {
    text-decoration: underline;
    letter-spacing: 1px;
    width: 50%;
    text-align: center;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
`

function Kerkbladen() {
  const {
    allWordpressWpDownloadType: { nodes: types },
    allWordpressWpDownloads: { nodes: downloads },
  } = useStaticQuery(graphql`
    query {
      allWordpressWpDownloadType {
        nodes {
          wordpress_id
          name
        }
      }

      allWordpressWpDownloads {
        nodes {
          wordpress_id
          title
          download_type

          acf {
            upload {
              url {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const [selected, setSelected] = useState(types[0].wordpress_id)

  const filteredDownloads = downloads.filter((download) => {
    if (!download.acf.upload.url) return false

    return download.download_type.includes(selected)
  })

  return (
    <div className="pl-4">
      <Select
        className="w-100 py-2 text-capitalize mb-4 col-lg-6 px-0"
        onChange={(e) => setSelected(parseInt(e.target.value))}
        value={selected}
      >
        {types.map((type) => (
          <option value={type.wordpress_id}>{type.name}</option>
        ))}
      </Select>

      <Downloads className="d-flex flex-wrap">
        {filteredDownloads.length > 0 ? (
          filteredDownloads.map((download, key) => (
            <a
              key={key}
              href={download.acf.upload.url.localFile.publicURL}
              target="_blank"
              download
            >
              {download.title}
            </a>
          ))
        ) : (
          <div>Geen downloads gevonden voor deze categorie.</div>
        )}
      </Downloads>
    </div>
  )
}

export default Kerkbladen
