import React from 'react'
import useSWR from 'swr'
import styled from 'styled-components'

import { getHighlighted, getPlaylists } from 'services/youtube'

import playButton from 'img/play-button.svg'

const StyledPlayButton = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  transition: 500ms;

  &:hover {
    transform: scale(1.1);
  }
`

function Playlists() {
  const { data: highlighted, error } = useSWR('playlists', getHighlighted, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
  })

  if (error)
    return (
      <div className="py-5">
        Er is iets fout gegaan met het ophalen van de gegevens.
      </div>
    )
  if (!highlighted) return <div className="py-5">Gegevens ophalen..</div>

  return (
    <div className="row">
      {highlighted.map((highlight) => (
        <div className="col-lg-4 mb-5">
          <a
            className="mb-3 d-block position-relative"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://youtube.com/watch?v=${highlight.snippet.resourceId.videoId}`}
          >
            <StyledPlayButton src={playButton} />
            <img
              className="w-100"
              src={highlight.snippet.thumbnails.high.url}
              alt=""
            />
          </a>

          <a
            className="d-block px-3"
            target="_blank"
            rel="noreferrer noopener"
            href={`https://youtube.com/watch?v=${highlight.snippet.resourceId.videoId}`}
          >
            {highlight.snippet.title}
          </a>
        </div>
      ))}
    </div>
  )
}

export default Playlists
