import dayjs from 'dayjs'

import dayjsLocale from 'dayjs/plugin/localeData'
import 'dayjs/locale/nl'

dayjs.locale('nl')
dayjs.extend(dayjsLocale)

const months = dayjs.months()

const baseUrl = 'https://admin.spakenburg-noord.nl/wp-json/noord/v1/playlists'
const playlistsUrl = (name) => `${baseUrl}/${name}`

export function getDateFromTitle(title) {
  const month = months.findIndex((month) => title.includes(month))
  if (month === -1) return false

  const year = parseInt(title.slice(-4))

  if (isNaN(year)) {
    return dayjs().month(month).format()
  }

  return dayjs().month(month).year(year).format()
}

export async function getVideosFromGeneral(playlistName = 'general') {
  const res = await fetch(playlistsUrl(playlistName))

  if (res.ok) {
    let videos = (await res.json()) || []

    videos = videos.map((video) => {
      video.typeDienst =
        dayjs(video.snippet.publishedAt).format('HH') === '20'
          ? 'Avonddienst'
          : 'Ochtenddienst'

      const date = getDateFromTitle(video.snippet.title)
      video.snippet.publishedAt = date ? date : video.snippet.publishedAt

      video.year = dayjs(video.snippet.publishedAt).format('YYYY')
      video.month = dayjs(video.snippet.publishedAt).format('MMMM')
      video.value = `${video.month} ${video.year}`

      return video
    })

    return videos
  }

  return []
}

export async function getPlaylists() {
  const res = await fetch(baseUrl)

  if (res.ok) {
    const { items } = (await res.json()) || []
    return items
  }

  return []
}

export async function getHighlighted() {
  const res = await fetch(playlistsUrl('highlighted'))

  if (res.ok) {
    const videos = (await res.json()) || []
    return videos
  }

  return []
}

export default getVideosFromGeneral
