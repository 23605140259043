import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import Content from 'components/shared/Content'
import ButtonInverse from 'components/elements/ButtonInverse'
import ButtonDefault from 'components/elements/ButtonDefault'
import WindowBig from 'components/mask/WindowBig'
import VideosByMonth from 'components/youtube/VideosByMonth'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import Blog from 'components/related/Blog'
import ParseContent from 'components/shared/ParseContent'
import { NewsTitle } from 'components/elements/NewsTitle'
import Playlists from 'components/youtube/Playlists'
import Image from 'components/shared/Image'
import Kerkbladen from 'components/kerkbladen'
import { Helmet } from 'react-helmet'

const DownloadsTitle = styled(ParseContent)`
  @media (min-width: 992px) {
    margin-left: -100px;
  }
`

const WindowContainer = styled.div`
  @media (max-width: 575px) {
    width: 50% !important;
    margin: -80px auto;
    height: 470px;
  }
`

const StyledContent = styled(Content)`
  @media (max-width: 767px) {
    & h1 {
      font-size: ${(props) => props.theme.font.size.xxl};
    }
  }
  @media (max-width: 575px) {
    & h2 {
      font-size: ${(props) => props.theme.font.size[30]};
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  const { banner, after_banner, news, videos, downloads, photos } = acf

  return (
    <Layout noChurch>
      <SEO yoast={yoast} path={path} article />
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>

      <section className="color-background-black color-light">
        <div className="container pt-5">
          <div className="row justify-content-between">
            <div className="col-sm-5 mt-5">
              <StyledContent
                className="font-size-80 text-uppercase mb-5"
                content={banner.description}
              />

              <ButtonInverse
                inAnchor
                to="https://www.youtube.com/channel/UCMzKp3cr_sdu_VZMJf7qD1Q"
              >
                {banner.button_text}
              </ButtonInverse>
            </div>

            <WindowContainer className="col-sm-6 mb-n5 d-flex">
              <WindowBig
                imageUrl={
                  banner.image && banner.image.localFile
                    ? banner.image.localFile.publicURL
                    : banner.image.url
                }
              />
            </WindowContainer>
          </div>
        </div>
      </section>

      <section id="videos" className="container py-4 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <StyledContent
              className="mb-4 mt-5 mt-lg-0"
              content={after_banner.description}
            />

            <VideosByMonth />
          </div>
        </div>
      </section>

      <section id="nieuws" className="container py-4 mb-5 pb-5">
        <div className="row pb-5 align-items-center">
          <div className="col-sm-6">
            <NewsTitle content={news.description} />
          </div>

          <div className="col-sm-6 d-flex justify-content-end">
            <ButtonDefault to={news.link.url}>{news.link.title}</ButtonDefault>
          </div>
        </div>

        <Blogik
          settings={{
            limit: 3,
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts }) => {
              return (
                <div>
                  {hasPosts && <Blog posts={posts} />}

                  {!hasPosts && <ParseContent content="Geen resultaten" />}
                </div>
              )
            }}
          </BlogConsumer>
        </Blogik>
      </section>

      <section className="color-background-main mb-5" id="videos">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center py-5">
              <ParseContent className="mb-5" content={videos.description} />

              <Playlists />

              <ButtonInverse
                theme="black"
                isAnchor
                to="https://www.youtube.com/channel/UCMzKp3cr_sdu_VZMJf7qD1Q"
              >
                {videos.button_text}
              </ButtonInverse>
            </div>
          </div>
        </div>
      </section>

      <section className="container mb-5" id="downloads">
        <div className="row">
          <div className="col-lg-4">
            <Image src={downloads.image} />
          </div>
          <div className="col-lg-8 pt-5">
            <DownloadsTitle
              content={downloads.description}
              className="mb-5 font-weight-bold"
            />

            <Kerkbladen />
          </div>
        </div>
      </section>

      <section id="instagramFeed" className="container mb-5">
        <div class="elfsight-app-12cb2549-9936-47ef-b851-e5e219d88532"></div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          button_text
          image {
            localFile {
              publicURL
            }
          }
        }

        after_banner {
          description
        }

        news {
          description
          link {
            title
            url
          }
        }

        videos {
          description
          button_text
        }

        downloads {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        photos {
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
